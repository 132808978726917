import React from "react";
import TekkrMarkdown from "../../../../../shared/tekkr-markdown";
import { MarkdownContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/markdown/blueprint";
import "@mdxeditor/editor/style.css";
import { SegmentContentViewProps } from "../segment-content";

export default function MarkdownSegmentContentView(
  props: SegmentContentViewProps<MarkdownContentBlueprint>
) {
  return (
    <div className={"flex flex-row justify-between w-full items-center"}>
      <TekkrMarkdown markdown={props.content.markdown}></TekkrMarkdown>
    </div>
  );
}

import { cn } from "../../lib/utils";
import React from "react";

export default function LoadingCard(props: { className?: string }) {
  return (
    <div className={"border rounded-lg p-4 text-center bg-popover opacity-45"}>
      <p className={cn("text-muted-foreground font-semibold", props.className)}>
        loading ...
      </p>
    </div>
  );
}

import React, { ReactElement } from "react";
import MarkdownSegmentContentView from "./types/markdown";
import ListSegmentContentView from "./types/list";
import PeopleListSegmentContentView from "./types/people-list";
import {
  BlueprintEditType,
  ContentBlueprint,
  ContentType,
  PlaybookStage,
} from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import SelectSegmentContentView from "./types/select";
import SectionSegmentContentView from "./types/section";
import BlueprintReferenceSegmentContentView from "./types/blueprint-reference";
import ExternalLinkSegmentContentView from "./types/external-link";
import EditableTextSegmentContentView from "./types/editable-text";
import { SegmentBlueprint } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";

export type SegmentContentViewProps<CT extends ContentBlueprint> = {
  segment: SegmentBlueprint;
  content: CT;
  edit: BlueprintEditType<CT> | undefined;
  isEditing: boolean;
};

const viewMap: Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<ContentType, (props: SegmentContentViewProps<any>) => ReactElement>
> = {
  [ContentType.markdown]: MarkdownSegmentContentView,
  [ContentType.list]: ListSegmentContentView,
  [ContentType.peopleList]: PeopleListSegmentContentView,
  [ContentType.select]: SelectSegmentContentView,
  [ContentType.section]: SectionSegmentContentView,
  [ContentType.blueprintReference]: BlueprintReferenceSegmentContentView,
  [ContentType.externalLink]: ExternalLinkSegmentContentView,
  [ContentType.editableText]: EditableTextSegmentContentView,
};

type Props = {
  playbookEdit?: PlaybookEdit;
  segment: SegmentBlueprint;
  content: SegmentBlueprint["content"][0];
  contentEdit?: object;
  isEditing: boolean;
};

function isHidden(props: Props, stage: PlaybookStage): boolean {
  const blueprint = props.content as ContentBlueprint;
  const visibility = blueprint.visibility;
  if (!visibility) {
    return false;
  }
  if ("hideIn" in visibility) {
    return visibility.hideIn.includes(stage);
  } else if ("onlyIn" in visibility) {
    return visibility.onlyIn !== stage;
  }
  return false;
}

export function SegmentContentView(props: Props) {
  const ViewType = viewMap[props.content.type];

  if (!ViewType) {
    throw new Error(`content type "${props.content.type}" not supported`);
  }

  const stage =
    props.playbookEdit === undefined
      ? PlaybookStage.discover
      : props.isEditing
        ? PlaybookStage.edit
        : PlaybookStage.read;
  if (isHidden(props, stage)) {
    return <></>;
  }

  return (
    <ViewType
      segment={props.segment}
      content={props.content}
      edit={props.contentEdit}
      isEditing={props.isEditing}
    />
  );
}

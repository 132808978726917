import { SegmentContentViewProps } from "../segment-content";
import { EditableTextContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/editable-text/blueprint";
import TekkrMarkdown from "../../../../tekkr-markdown";
import {
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin, Realm, realmPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { useContext, useState } from "react";
import { PlaybookPageContext } from "../../../../../../pages/playbook/playbook-page";
import ConfirmationDialog from "../../../../../../modals/confirmation-dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { RotateCw } from "lucide-react";
import React from "react";

export default function EditableTextSegmentContentView(
  props: SegmentContentViewProps<EditableTextContentBlueprint>
) {
  const { controller } = useContext(PlaybookPageContext);
  const [editorKey, setEditorKey] = useState<number>(0);
  const text = props.edit?.textMarkdown ?? props.content.defaultText;
  if (!props.isEditing) {
    if (!text) {
      return <></>;
    }
    return <TekkrMarkdown markdown={text} />;
  } else {
    const onChange = (markdown: string) => {
      if (props.edit) {
        props.edit.textMarkdown = markdown;
        controller.editUpdated();
      }
    };
    const reset = () => {
      onChange(props.content.defaultText!);
      setEditorKey(editorKey + 1);
    };
    return (
      <div className={"mb-2"}>
        <MDXEditor
          key={editorKey}
          className={"text-foreground bg-accent"}
          onChange={onChange}
          markdown={text ?? ""}
          contentEditableClassName={"mdxeditor-prose"}
          plugins={[headingsPlugin(), listsPlugin(), quotePlugin(), markdownShortcutPlugin()]}
        />
        {props.content.defaultText && (
          <ConfirmationDialog
            title={"Restore Original Content?"}
            body={
              <div>
                <p>
                  Are you sure you want to restore the original content? Doing
                  so will replace your changes with the following:
                </p>
                <div className={"flex flex-row mt-2 gap-3"}>
                  <div className={"w-1 bg-muted"}></div>
                  <TekkrMarkdown
                    className={"text-muted-foreground text-sm rounded-md"}
                    markdown={props.content.defaultText!}
                  />
                </div>
              </div>
            }
            confirmButtonText={"Restore"}
            onConfirm={() => reset()}
          >
            <DialogTrigger asChild>
              <p
                className={
                  "hover:underline transition-all inline-block cursor-pointer text-sm mt-2 text-muted-foreground"
                }
              >
                <RotateCw className={"inline w-4 h-4 me-2"} />
                Restore Original Content
              </p>
            </DialogTrigger>
          </ConfirmationDialog>
        )}
      </div>
    );
  }
}

import { Button } from "../ui/button";
import { Mail } from "lucide-react";
import React from "react";
import TeamAvatars from "./team-avatars";

export default function HelpFooter(props: { variant?: "secondary" }) {
  return (
    <div className={"flex flex-col gap-2 w-full"}>
      <TeamAvatars />
      <p>
        Need help?
        <br />
        <span className={"text-sm text-center text-muted-foreground"}>
          Our ground control is ready to assist!
        </span>
      </p>
      <div className={"flex flex-col items-center mt-4"}>
        <Button variant={props.variant} asChild>
          <a href={"https://www.tekkr.io/contact-us"}>
            <Mail className={"w-4 h-4 mr-2"} />
            Write a message
          </a>
        </Button>
      </div>
    </div>
  );
}

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { OnboardingPage } from "../common/layout";
import { Button } from "../../components/ui/button";
import { useAuth, useAuthController } from "../../auth/auth-provider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient, ApiError } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import LoadingCard from "../../components/shared/loading-card";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { DoorOpen } from "lucide-react";
import HelpFooter from "../../components/shared/help-footer";
import { confetti } from "@tsparticles/confetti";
import React from "react";

export default function InvitePage() {
  const params = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!params.inviteToken || params.inviteToken === "") {
    throw new Error("missing token");
  }
  const token = params.inviteToken;

  const query = useQuery({
    queryKey: ["invite", token],
    queryFn: async () => {
      const response = await (await apiClient).getInvite({ params: { token } });
      return response.body as ClientInferResponseBody<
        typeof apiContract.getInvite,
        200
      >;
    },
  });

  useEffect(() => {
    document.title = `Join ${query.data ? `"${query.data.orgName}"` : "Organization"} - Tekkr`;
  }, [query.data]);

  const authController = useAuthController();
  const mutation = useMutation({
    mutationFn: async (clickPos: { x: number; y: number }) => {
      void confetti({
        particleCount: 100,
        spread: 360,
        startVelocity: 35,
        origin: clickPos,
      });
      await (
        await apiClient
      ).acceptInvite({
        params: { token },
      });
      await authController.refetch();
      navigate("/");
    },
  });

  if (query.isError) {
    return <Navigate to={"/404"} />;
  }

  return (
    <OnboardingPage>
      {query.isPending || mutation.isPending ? <LoadingCard /> : null}
      {query.data ? (
        <div className={"flex flex-col gap-1"}>
          <div className={"flex flex-col items-center mb-4"}>
            <DoorOpen className={"w-10 h-10"} />
          </div>
          <h1 className={"mb-4"}>Join {query.data.orgName}</h1>
          <h4>
            Hey {(user.displayName ?? user.email ?? "there").split(" ")[0]},
            welcome to Tekkr!
          </h4>
          <span className={"text-muted-foreground text-sm"}>
            Tekkr is used by tech leaders in VC-backed startups to remove
            bottlenecks, align teams and achieve business milestones faster.
          </span>
          <span className={"mt-2"}>
            Join <span className={"font-semibold"}>{query.data.orgName}</span>{" "}
            now to get access to Tekkr Playbooks. 👇
          </span>
          <Button
            onClick={(e) =>
              mutation.mutate({
                x: e.pageX / window.innerWidth,
                y: e.pageY / window.innerHeight,
              })
            }
            disabled={mutation.isPending}
            className={
              "w-full hover:drop-shadow-glow-primary transition-all duration-500 mt-4"
            }
          >
            Accept Invite
          </Button>
          <div className={"text-sm text-muted-foreground mt-4"}>
            This link is valid until{" "}
            {DateTime.fromISO(query.data.validUntil).toLocaleString({
              month: "long",
              day: "numeric",
            })}
          </div>
          <hr className={"my-4"} />
          <div className={"text-center"}>
            <HelpFooter variant={"secondary"} />
          </div>
        </div>
      ) : null}
    </OnboardingPage>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.peopleListContentBlueprint = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
exports.peopleListContentBlueprint = blueprint_1.editableContentBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.ContentType.peopleList),
    title: zod_1.z.string(),
    description: zod_1.z.string().optional(),
}).strict();

import { Button } from "../ui/button";
import {
  BookLock,
  Building,
  Cloud,
  LifeBuoy,
  LogOut,
  MoonStar,
  Sun,
} from "lucide-react";
import { ReactComponent as TekkrLogoWhite } from "../../static/tekkr-logo-white.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useAuthController } from "../../auth/auth-provider";
import { useColorScheme } from "../../lib/color-scheme";
import { OrgSelector, useOrg, useOrgController } from "../../auth/org-provider";
import { config } from "../../lib/config";
import { getInitialsForName } from "../../lib/utils";
import { useGlobalDialogs } from "../../modals/global-dialogs";

function Navbar() {
  const { isDark, setIsDark } = useColorScheme();
  function toggleDarkMode() {
    setIsDark(!isDark);
  }

  const auth = useAuth();
  const authController = useAuthController();
  const orgController = useOrgController();
  const org = useOrg();

  const globalDialogs = useGlobalDialogs();

  const navigate = useNavigate();
  function logout() {
    authController
      .logout()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function selectOrg(id: string) {
    OrgSelector.setSelectedOrg(id);
    orgController.refetch();
  }

  // todo fix name and avatar placholder
  return (
    <div>
      <div className={"h-16"}></div>
      <div
        className={
          "px-8 h-16 border-b-accent border-b-2 fixed right-0 left-0 top-0 bg-background z-40"
        }
      >
        <div className={"py-3 flex items-center justify-between"}>
          <div className={"flex gap-4 items-center"}>
            <Link to={"/"}>
              <div
                className={
                  "cursor-pointer w-10 h-10 bg-primary rounded-lg flex flex-col items-center justify-center"
                }
              >
                <TekkrLogoWhite />
                {config.envTitle ? (
                  <code
                    className={"font-semibold text-primary-foreground"}
                    style={{ fontSize: "0.5rem" }}
                  >
                    {config.envTitle}
                  </code>
                ) : null}
              </div>
            </Link>
            <Button asChild variant={"ghost"}>
              <Link to={"/library"}>My Playbooks</Link>
            </Button>
            { !("unrestricted" in org.playbookAccess) && <Button
              onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}
              variant={"default"}
              className={
                "hover:drop-shadow-glow-primary transition-all duration-500"
              }
            >
              <BookLock className={"w-4 h-4 mr-2"} />
              <span>Unlock all Playbooks</span>
            </Button> }
          </div>
          <div className={"flex gap-4 items-center"}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar data-testid={"button-navbar-account"} className={"w-9 h-9 cursor-pointer"}>
                  <AvatarImage
                    src={auth.user?.photoURL ?? undefined}
                    alt="Profile"
                  />
                  <AvatarFallback>
                    {getInitialsForName(auth.user.displayName ?? "")}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel
                  onClick={() => { if (config.env !== "prod" ) auth.user.getIdToken().then(console.log) }}
                >
                  {auth.user?.displayName}
                  <br />
                  <span className={"text-xs text-muted-foreground"}>
                    {auth.user?.email}
                  </span>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => toggleDarkMode()}>
                    {isDark ? (
                      <Sun className="mr-2 h-4 w-4" />
                    ) : (
                      <MoonStar className="mr-2 h-4 w-4" />
                    )}
                    <span>{isDark ? "Light" : "Dark"} Mode</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => navigate(`/org/${org.id}`)}>
                  <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center"}>
                      <Building className="mr-2 h-4 w-4" />
                      <span>Manage Organization</span>
                    </div>
                    <div className={"ml-6 text-sm text-muted-foreground"}>
                      {org?.name}
                    </div>
                  </div>
                </DropdownMenuItem>
                {config.env !== "prod" && auth.account.orgs.length > 1 && (
                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                      <span>Switch Organization</span>
                    </DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent>
                        {auth.account.orgs.map((org) => (
                          <DropdownMenuItem key={org.id} onClick={() => selectOrg(org.id)}>
                            <Building className={"w-4 h-4 me-2"} />
                            <span>{org.name}</span>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                )}
                <DropdownMenuSeparator />
                <Link to={"https://tekkr.io/contact-us"} target={"_blank"}>
                  <DropdownMenuItem>
                      <LifeBuoy className="mr-2 h-4 w-4" />
                      <span>Support</span>
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuItem className={"hidden"} disabled>
                  <Cloud className="mr-2 h-4 w-4" />
                  <span>API</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => logout()}>
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { Slack } from "lucide-react";
import Spinner from "../../components/ui/spinner";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { useOrg } from "../../auth/org-provider";
import { Button } from "../../components/ui/button";
import HelpFooter from "../../components/shared/help-footer";

export default function InstallSlackPage() {
    const [queryParams] = useSearchParams();
    const org = useOrg();
    const navigate = useNavigate();

    const code = queryParams.get("code");
    const validCode = !!code && code.trim().length > 1;

    const mutation = useQuery({
        queryKey: ["install-slack", org.id],
        queryFn: async () => {
            await (await apiClient).installSlackToOrg({
                body: {
                    code: code!,
                    redirect: `https://${window.location.host}/install-slack`,
                }
            });
            navigate(`/org/${org.id}?import_users=slack`);
        },
        enabled: validCode,
        retry: () => false,
    });

    if (!validCode) {
        return <Navigate to={"/"} />
    }

    return <div className={"flex flex-col gap-6"}>
        <h1 className={"w-full justify-start flex flex-row items-center gap-2"}><Slack />Installing Slack
        </h1>

        { mutation.isError && <>
            <div className={"border border-destructive text-destructive-foreground rounded-md px-4 py-2 bg-destructive"}>
                <p className={"text-sm"}>
                    <p className={"font-semibold"}>
                        Oh no, something went wrong while connecting Tekkr and Slack 😢
                    </p>
                    <p className={"opacity-85 mt-1"}>
                        Please <a className={"underline"} href={"https://tekkr.io/contact-us"} target={"_blank"} rel="noreferrer">contact us</a> about this issue.
                    </p>
                </p>
            </div>


            <div>
                <Button variant={"outline"} asChild><Link to={"/"}>Go Home</Link></Button>
            </div>
        </>}

        {mutation.isPending && <>
            <p>We're installing Slack in your Organization <b>&quot;{org.name}&quot;</b>. This will only take a moment.
            </p>
            <div className={"flex flex-row items-center gap-2 mt-4"}><Spinner /> Installing...</div>

        </>}

        <hr className={"my-6"} />
        <div className={"text-center"}>
            <HelpFooter variant={"secondary"} />
        </div>
    </div>
}
import { OnboardingPage } from "../common/layout";
import HelpFooter from "../../components/shared/help-footer";
import { Button } from "../../components/ui/button";
import { Calendar } from "lucide-react";
import CustomerLogos from "../../components/shared/customer-logos";
import React from "react";

export default function NoOrgPage() {
  return (
    <OnboardingPage>
      <div className={"w-full flex flex-col gap-2"}>
        <h1 className={"w-full text-start"}>Get started.</h1>
        <br />
        <p className={"font-semibold"}>
          &#127970;&nbsp;&nbsp;Already using Tekkr?
        </p>
        <p>
          Ask a teammate to send you an invite link to join your organization.
        </p>
        <br />
        <p className={"font-semibold"}>
          &#128640;&nbsp;&nbsp;Your company doesn't use Tekkr yet?
        </p>
        <p>
          Let's schedule a call to get you started with Tekkr, the all-in-one
          solution to build unicorn teams.
        </p>
        <div className={"mt-2"}>
          <Button
            asChild
            className={
              "hover:drop-shadow-glow-primary transition-all duration-500"
            }
          >
            <a href={"https://tekkr.io/request-access"}>
              <Calendar className={"w-5 h-5 me-2"} />
              Book a Call
            </a>
          </Button>
        </div>

        <CustomerLogos className={"mt-8"} />

        <hr className={"my-6"} />
        <div className={"flex flex-col items-center text-center"}>
          <HelpFooter variant={"secondary"} />
        </div>
      </div>
    </OnboardingPage>
  );
}

import { config } from "../lib/config";
import {
  initClient,
  InitClientArgs,
  InitClientReturn,
  tsRestFetchApi,
} from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { getFirebaseAuth } from "../auth/firebase";
import { ApiFetcherArgs } from "@ts-rest/core/src/lib/client";
import { OrgSelector } from "../auth/org-provider";

export class ApiError extends Error {
  constructor(
    readonly status: number,
    readonly body: unknown
  ) {
    super(`Request failed with status code ${status}`);
  }
}

export const apiClient: Promise<
  InitClientReturn<typeof apiContract, InitClientArgs>
> = (async () => {
  return initClient(apiContract, {
    baseUrl: config.serviceHost,
    api: async (args: ApiFetcherArgs) => {
      // Add anything you want here!
      const token = await getFirebaseAuth().currentUser?.getIdToken();
      if (!token) {
        throw new Error("no authorization token available");
      }
      args.headers["Authorization"] = token;
      const orgId = OrgSelector.getSelectedOrgId();
      if (orgId) {
        args.headers["X-Tekkr-Org"] = orgId;
      }
      const res = await tsRestFetchApi(args);
      if (res.status < 200 || res.status >= 400) {
        throw new ApiError(res.status, res.body);
      }
      return res;
    },
  });
})();

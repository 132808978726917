export const testMarkdown = `
# Title 1
## Title 2
### Title 3
#### Title 4
Normal text *italic text* **bold text**
::arrow[arrow]
::info[info]
:::infobox
::arrow[arrow in box]
:::
::numbered[number 1]{#1}
> quote

[link](https://google.com)
![image](https://media.licdn.com/dms/image/v2/C4E03AQEcPWm0fTepmA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1657547204289?e=1733356800&v=beta&t=J9USizrSIG6A5a1fM4t8l0Hp6AjcRqzaNU_Rfw02rXU)
- list item 1
- list item 2
`;
import { Dialog, DialogContent } from "../components/ui/dialog";
import React from "react";
import CustomerLogos from "../components/shared/customer-logos";
import { Calendar, CheckCircle, UserPlus } from "lucide-react";
import { Button } from "../components/ui/button";
import TeamAvatars from "../components/shared/team-avatars";

export default function UnlockAccessCtaDialog(
  props: React.PropsWithChildren & {
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
  }
) {
  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      {props.children}
      <DialogContent className="sm:max-w-lg text-center p-12 flex flex-col items-center gap-8">
        <div className={"flex flex-col gap-2"}>
          <TeamAvatars className={"mb-2"} />
          <h2>Unlock all Tekkr Playbooks</h2>
          <p className={"text-muted-foreground"}>
            Access the all-in-one solution for Tech Leaders to align efforts,
            remove bottlenecks and achieve business milestones faster.
          </p>
        </div>

        <div
          className={
            "text-start flex flex-col gap-2 text-sm border rounded-md p-4"
          }
        >
          <p className={"font-semibold"}>What's included?</p>
          <p className={"flex flex-row items-center gap-3"}>
            <CheckCircle className={"text-muted-foreground inline w-5 h-5"} />{" "}
            Access to all Tekkr Playbooks
          </p>
          <p className={"flex flex-row items-center gap-3"}>
            <CheckCircle className={"text-muted-foreground inline w-5 h-5"} />{" "}
            Dedicated implementation support
          </p>
          <p className={"flex flex-row items-center gap-3"}>
            <CheckCircle className={"text-muted-foreground inline w-5 h-5"} />{" "}
            Built-in tracking tools that give real-time insights
          </p>
          <p className={"flex flex-row items-center gap-3"}>
            <UserPlus className={"text-muted-foreground inline w-5 h-5"} />{" "}
            Tekkr Leadership Advisory (Optional)
          </p>
        </div>

        <div className={"flex flex-row justify-center gap-4"}>
          <a
            href={"https://tekkr.io/schedule-setup"}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button
              className={
                "hover:drop-shadow-glow-primary transition-all duration-500"
              }
            >
              <Calendar className={"w-5 h-5 inline me-2"} /> Book a Call
            </Button>
          </a>
          <a
            href={"https://tekkr.io/pricing"}
            target={"_blank"}
            rel="noreferrer"
          >
            <Button variant={"outline"}>Learn more</Button>
          </a>
        </div>

        <CustomerLogos className={"mt-6"} />
      </DialogContent>
    </Dialog>
  );
}

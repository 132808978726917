import { User } from "firebase/auth";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getFirebaseAuth } from "./firebase";
import { apiClient } from "../service/tekkr-service";
import { useQuery } from "@tanstack/react-query";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";

type HttpAccount = ClientInferResponseBody<typeof apiContract.getAccount, 200>;

interface AuthController {
  auth: { user: User; account: HttpAccount } | null;
  logout: () => Promise<void>;
  refetch: () => Promise<void>;
}

const AuthContext = createContext<AuthController>({} as AuthController);
export const useAuth = () => {
  const controller = useContext(AuthContext);
  if (!controller.auth) {
    throw new Error("No auth context available.");
  }
  return controller.auth;
};
export const useAuthController = () => {
  return useContext(AuthContext);
};
const authQuery = {
  queryKey: ["auth"],
  queryFn: async () => {
    const auth = getFirebaseAuth();
    await auth.authStateReady();
    if (auth.currentUser) {
      if ("amplitude" in window) {
        const amplitude = window.amplitude as { setUserId: (id: string) => void };
        amplitude.setUserId(auth.currentUser.uid);
      }
      const response = (await (await apiClient).getAccount())
        .body as HttpAccount;
      return { user: auth.currentUser, account: response };
    }
    return null;
  },
};
export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const { isPending, data, refetch } = useQuery(authQuery);
  return (
    <AuthContext.Provider
      value={{
        auth: data ?? null,
        refetch: async () => {
          await refetch();
        },
        logout: async () => {
          await getFirebaseAuth().signOut();
          await refetch();
        },
      }}
    >
      {!isPending ? children : null}
    </AuthContext.Provider>
  );
};
export const RequireAuth = (props: PropsWithChildren) => {
  const controller = useContext(AuthContext);
  const location = useLocation();
  if (!controller.auth) {
    return (
      <Navigate
        to={{ pathname: "/login" }}
        state={{ from: location.pathname }}
        replace
      />
    );
  }
  return <>{props.children}</>;
};

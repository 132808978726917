import { QueryClient } from "@tanstack/react-query";
import { ApiError } from "../service/tekkr-service";

export const defaultQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 1000,
            retry: (count, e) => {
                if (e instanceof ApiError && Math.floor(e.status / 100) === 4) {
                    // do not retry 4xx errors
                    return false;
                }
                return count < 4;
            },
        },
    },
})
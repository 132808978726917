import { ReactComponent as Parloa } from "../../static/partner-logos/parloa.svg";
import { ReactComponent as Prima } from "../../static/partner-logos/prima.svg";
import { ReactComponent as Workpath } from "../../static/partner-logos/workpath.svg";
import { ReactComponent as Forto } from "../../static/partner-logos/forto.svg";
import { ReactComponent as Vimcar } from "../../static/partner-logos/vimcar.svg";
import React from "react";

export default function CustomerLogos(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...props}>
      <p
        className={
          "text-xs mb-2 font-semibold text-muted-foreground text-center"
        }
      >
        Trusted by Tech Leaders at
      </p>
      <div className={"grid grid-cols-5 gap-x-3 items-stretch justify-center"}>
        <div className={"flex flex-col items-center justify-center"}>
          <Parloa className={"[&_*]:fill-foreground"} />
        </div>
        <div className={"flex flex-col items-center justify-center"}>
          <Prima className={"[&_*]:fill-foreground"} />
        </div>
        <div className={"flex flex-col items-center justify-center"}>
          <Workpath className={"[&_*]:fill-foreground"} />
        </div>
        <div className={"flex flex-col items-center justify-center"}>
          <Forto className={"[&>_*]:fill-foreground"} />
        </div>
        <div className={"flex flex-col items-center justify-center"}>
          <Vimcar className={"[&_*]:fill-foreground"} />
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from "react";
import { Badge } from "../../../ui/badge";
import { Checkbox } from "../../../ui/checkbox";
import { Button } from "../../../ui/button";
import { ChevronDown, Eye, EyeOff } from "lucide-react";
import { SegmentContentView } from "./content/segment-content";
import {
  SegmentBlueprint,
  SegmentStyle,
} from "tekkr-common/dist/model/playbook/segment/blueprint";
import { SegmentEdit } from "tekkr-common/dist/model/playbook/segment/edit";
import { Collapsible, CollapsibleContent } from "../../../ui/collapsible";
import { cn } from "../../../../lib/utils";
import { PlaybookPageContext } from "../../../../pages/playbook/playbook-page";
import { PlaybookViewControllerContext } from "../playbook-view";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";

export default function PlaybookSegment(props: {
  playbookEdit?: PlaybookEdit;
  segment: SegmentBlueprint;
  index: number;
  segmentEdit?: SegmentEdit;
  isEditing: boolean;
}) {
  const [done, setDoneState] = React.useState(props.segmentEdit?.done);
  const [hidden, setHiddenState] = React.useState(props.segmentEdit?.hidden);
  const [expanded, setExpanded] = React.useState(false);

  const { controller: pageController } = useContext(PlaybookPageContext);
  const viewController = useContext(PlaybookViewControllerContext);

  const edit = props.segmentEdit;

  if (edit && !edit.content) {
    edit.content = {};
  }
  function setDone(done: boolean) {
    if (!edit || edit.done === done) {
      return;
    }
    edit.done = done;
    if (done) {
      if (expanded) {
        setTimeout(() => viewController.goToSegment(props.index), 100);
        setExpanded(false);
      }
    }
    pageController.editUpdated();
    setDoneState(done);
  }
  function setHidden(hidden: boolean) {
    if (!edit) {
      return;
    }
    edit.hidden = hidden;
    pageController.editUpdated();
    setExpanded(false);
    setHiddenState(hidden);
  }

  const content = (
    <div className={"flex flex-col gap-2"}>
      {props.segment.content.map((content, index) => {
        if ("id" in content && edit && !edit.content![content.id]) {
          edit.content![content.id] = {};
        }
        return (
          <SegmentContentView
            key={index}
            segment={props.segment}
            content={content}
            isEditing={props.isEditing}
            playbookEdit={props.playbookEdit}
            contentEdit={
              "id" in content && edit ? edit.content?.[content.id] : undefined
            }
          />
        );
      })}
    </div>
  );

  if (props.segment.style === SegmentStyle.plain) {
    return <div className={"my-4"}>{content}</div>;
  } else if (props.segment.style === SegmentStyle.card) {
    return (
      <>
        <div
          style={{ scrollMarginTop: "56pt" }}
          className={"group rounded-lg border relative bg-background"}
        >
          <div className={"overflow-hidden relative rounded-lg"}>
            <div
              className={cn(
                "flex flex-col p-5 gap-4",
                hidden ? "opacity-45" : null
              )}
            >
              <div className={"flex flex-row justify-between"}>
                <Badge
                  variant={"mini_accent"}
                  className={
                    done && !hidden
                      ? "bg-confirmation text-confirmation-foreground"
                      : undefined
                  }
                >
                  {props.segment.name}
                </Badge>
                {props.isEditing ? (
                  <div
                    className={cn(
                      "flex items-center space-x-2",
                      hidden ? "hidden" : null
                    )}
                  >
                    <Checkbox
                      variant={done ? "confirmation" : "primary"}
                      id={`done-segment-${props.segment.id}`}
                      checked={done}
                      onCheckedChange={(done) => setDone(!!done)}
                    />
                    <label
                      htmlFor={`done-segment-${props.segment.id}`}
                      className=" text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      done
                    </label>
                  </div>
                ) : null}
              </div>
              <h3
                className={!hidden ? "cursor-pointer" : undefined}
                onClick={() => setExpanded(!expanded)}
              >
                {props.segment.title}
                <Button
                  className={cn("w-6 h-6 p-1 ml-2", hidden ? "hidden" : null)}
                  style={{ verticalAlign: "middle" }}
                  variant={"ghost"}
                  size={"icon"}
                >
                  <ChevronDown
                    className={"transition-all"}
                    style={{
                      transform: expanded ? "rotate(-180deg)" : undefined,
                    }}
                  />
                </Button>
              </h3>
              <Collapsible
                open={expanded && !hidden}
                onOpenChange={setExpanded}
              >
                <CollapsibleContent className="space-y-2">
                  <hr className={"-mx-10 border-0 border-b border-b-accent"} />
                  {content}
                </CollapsibleContent>
              </Collapsible>
            </div>
            <div
              className={cn(
                "w-1 top-0 bottom-0 absolute",
                hidden
                  ? "bg-secondary"
                  : done
                    ? "bg-confirmation"
                    : "bg-primary"
              )}
            ></div>
            {props.isEditing && expanded ? (
              <Button
                className={"w-full rounded-none bg-accent hover:bg-muted"}
                onClick={() => setDone(true)}
                variant={"ghost"}
              >
                {done ? "Close" : "Mark as done:"} &quot;
                {props.segment.title}&quot;
              </Button>
            ) : null}
          </div>
          {props.isEditing ? (
            <div className={"opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 w-12 absolute -right-14 top-0 flex flex-col"}>
              <Button
                onClick={() => setHidden(!hidden)}
                size={"sm"}
                variant={"secondary"}
                className={"w-8 h-8 p-2 opacity-65"}
              >
                {hidden ? <Eye /> : <EyeOff />}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  } else {
    throw new Error(`segment style "${props.segment.style}" is not supported.`);
  }
}

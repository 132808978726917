import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { SegmentStyle } from "tekkr-common/dist/model/playbook/segment/blueprint";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}

export function getInitialsForName(name: string): string {
  const split = name.split(" ");
  if (split.length < 2) return split[0].charAt(0).toUpperCase();
  return (
    split[0].charAt(0).toUpperCase() +
    split[split.length - 1].charAt(0).toUpperCase()
  );
}

export function computePlaybookProgress(
  blueprint: PlaybookBlueprint,
  edit: PlaybookEdit | undefined
): number | undefined {
  let count = 0,
    done = 0;
  for (const segment of blueprint.segments) {
    if (edit?.segments?.[segment.id]?.hidden) continue;
    if (segment.style !== SegmentStyle.card) continue;
    count += 1;
    done += edit?.segments?.[segment.id]?.done ? 1 : 0;
  }
  return done / count;
}

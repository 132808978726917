import React, { createContext, useContext } from "react";
import UnlockAccessCtaDialog from "./unlock-access-cta-dialog";

type Trigger = () => void;
interface Context {
  showUnlockAllPlaybooksCta: Trigger;
}
const GlobalDialogsContext = createContext<Context | undefined>(undefined);

export function useGlobalDialogs() {
  return useContext(GlobalDialogsContext);
}

export const GlobalDialogsProvider = (props: React.PropsWithChildren) => {
  const [unlockCtaOpen, setUnlockCtaOpen] = React.useState(false);
  const context: Context = {
    showUnlockAllPlaybooksCta: () => setUnlockCtaOpen(true),
  };
  return (
    <GlobalDialogsContext.Provider value={context}>
      <UnlockAccessCtaDialog
        open={unlockCtaOpen}
        onOpenChange={setUnlockCtaOpen}
      />
      {props.children}
    </GlobalDialogsContext.Provider>
  );
};

import { OnboardingPage } from "../common/layout";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import Spinner from "../../components/ui/spinner";
import { apiClient } from "../../service/tekkr-service";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { OrgSelector, useOrgController } from "../../auth/org-provider";
import { useAuthController } from "../../auth/auth-provider";
import React from "react";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";

export default function CreateOrgPage() {
  const authController = useAuthController();
  const orgController = useOrgController();
  const [orgName, setOrgName] = useState<string>("");
  const query = useQuery({
    // todo use mutation here
    queryKey: ["create-org"],
    queryFn: async () => {
      const res = await (
        await apiClient
      ).createOrg({
        body: {
          name: orgName,
        },
      });
      const body = res.body as ClientInferResponseBody<typeof apiContract.createOrg, 201>
      await authController.refetch();
      OrgSelector.setSelectedOrg(body.id);
      await orgController.refetch();
      return true;
    },
    enabled: false,
  });

  useEffect(() => {
    document.title = "Create Organization - Tekkr";
  });

  if (orgController.org) {
    return <Navigate to={{ pathname: "/" }} replace />;
  }

  // todo fix learn more link
  return (
    <OnboardingPage>
      <div className={"w-full flex flex-col gap-2"}>
        <h1 className={"w-full text-start"}>
          Create a new organization
        </h1>
        <p className={"my-4"}>
          Organizations are shared environments where teams can discover and
          edit new Playbooks, and access implemented ones. Let's get started.
        </p>
        {!query.isFetching ? (
          <>
            <div className="grid w-full max-w-sm items-center gap-1.5 my-2">
              <Label htmlFor={"org-name-input"}>Organization Name</Label>
              <Input
                className={"w-full"}
                value={orgName}
                onChange={(v) => setOrgName(v.target.value)}
                id={"org-name-input"}
                placeholder={"Acme Corporation"}
              ></Input>
            </div>
            <Button onClick={() => query.refetch()}>Create Organization</Button>
          </>
        ) : (
          <div className={"flex flex-row items-center gap-3 justify-center"}>
            <Spinner className={""} />
            <p className={"text-sm font-semibold"}>
              Creating your organization...
            </p>
          </div>
        )}
        <hr className={"my-4"} />
        <h4>Is your company already using Tekkr?</h4>
        <p className={"mt-2"}>
          Ask for an invite to join your company's existing organization. Or{" "}
          <a href={"https://www.tekkr.io/contact-us"}>contact us</a> if you need
          help.
        </p>
      </div>
    </OnboardingPage>
  );
}

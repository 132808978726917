import React, { createContext, useContext } from "react";
import { apiClient } from "../service/tekkr-service";
import { Navigate } from "react-router-dom";
import { useAuth } from "./auth-provider";
import { useQuery } from "@tanstack/react-query";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import _ from "lodash";
import { Org } from "../service/tekkr-service-types";

export const OrgSelector = {
  getSelectedOrgId: (): string | null => {
    return window.localStorage.getItem("tekkr-selected-org");
  },
  setSelectedOrg: (orgId: string) => {
    window.localStorage.setItem("tekkr-selected-org", orgId);
  },
};

export const UseOrgContext = createContext<OrgController>({} as OrgController);
export const useOrg = (): Org => {
  const controller = useContext(UseOrgContext);
  if (!controller?.org) {
    throw new Error("No org available.");
  }
  return controller.org;
};

interface OrgController {
  refetch: () => Promise<unknown>;
  org: Org | null;
}
export const useOrgController = (): OrgController => {
  return useContext(UseOrgContext);
};

const orgQuery = () => ({
  queryKey: ["org"],
  queryFn: async () => {
    const response = await (await apiClient).getOrg();
    const body = response.body as ClientInferResponseBody<
      typeof apiContract.getOrg,
      200
    >;
    body.users = _.sortBy(body.users, "name");
    return body;
  },
});

export const MockOrgProvider = ({
  children,
  mockOrg,
}: React.PropsWithChildren & { mockOrg: Org }) => {
  const controller = {
    org: mockOrg,
    refetch: async () => {},
  };
  return (
    <UseOrgContext.Provider value={controller}>
      {children}
    </UseOrgContext.Provider>
  );
};

export const OrgProvider = ({ children }: React.PropsWithChildren) => {
  const auth = useAuth();

  const selectedOrgId = OrgSelector.getSelectedOrgId();
  // set org id to any org if none is set or selection doesn't exist in current account
  const selectedOrgMissing = !auth.account.orgs.find((o) => o.id === selectedOrgId)
  if (
      (selectedOrgMissing || !selectedOrgId) && auth.account.orgs.length > 0
  ) {
    OrgSelector.setSelectedOrg(auth.account.orgs[0]?.id);
  }
  const { isPending, data, refetch } = useQuery(orgQuery());

  // todo show a loading state here

  return (
    <UseOrgContext.Provider
      value={{
        org: data ?? null,
        refetch,
      }}
    >
      {!isPending ? children : undefined}
    </UseOrgContext.Provider>
  );
};
export const RequireOrg = (props: React.PropsWithChildren) => {
  const auth = useAuth();
  const controller = useContext(UseOrgContext);
  if (controller.org) {
    // user has an organization
    return <>{props.children}</>;
  }
  if (auth.account.access.createOrg) {
    return <Navigate to={{ pathname: "/create-organization" }} />;
  } else {
    return <Navigate to={{ pathname: "/no-organization" }} />;
  }
};

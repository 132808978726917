import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import { Input } from "../components/ui/input";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient, ApiError } from "../service/tekkr-service";
import Spinner from "../components/ui/spinner";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../components/ui/button";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { useNavigate } from "react-router-dom";
import { PlaybookPageNavigationState } from "../pages/playbook/playbook-page";
import { BookPlus } from "lucide-react";

type BlueprintContext = Pick<PlaybookBlueprint, "id" | "title">;
type PropsType = React.PropsWithChildren & { blueprint: BlueprintContext };

function CreatePlaybookDialogContent(
  props: PropsType & { updateKey: () => void }
) {
  const [name, setName] = React.useState(props.blueprint.title);

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (input: { title: string }) => {
      const response = await (
        await apiClient
      ).createPlaybook({
        body: {
          blueprintId: props.blueprint.id,
          title: input.title,
        },
      });
      const playbook = response.body as ClientInferResponseBody<
        typeof apiContract.createPlaybook,
        201
      >;
      const navState: PlaybookPageNavigationState = {
        openEditMode: true,
      };
      navigate(`/playbook/${playbook.id}`, { state: navState });
    },
  });

  function resetDialog() {
    props.updateKey();
  }

  const [open, setOpenState] = React.useState(false);
  const setOpen = (open: boolean) => {
    if (mutation.isPending) return;
    if (!open) {
      resetDialog();
    }
    setOpenState(open);
  };

  // todo validate input (and disable button and show messages)
  // todo fail if playbook title already exists

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      {props.children}
      {mutation.isPending ? (
        <DialogContent className="sm:max-w-xl">
          <DialogHeader>
            <DialogTitle>Create Playbook</DialogTitle>
          </DialogHeader>
          <div className={"flex flex-col items-center h-32 justify-center"}>
            <Spinner></Spinner>
            <div className={"text-sm font-semibold mt-2"}>
              Creating Playbook...
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className="sm:max-w-xl">
          <DialogHeader>
            <DialogTitle>Create Playbook</DialogTitle>
            <DialogDescription>
              You're creating a copy of <i>{props.blueprint.title}</i>, enter a
              name below so you can easily find it in your library later.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-4 items-start">
              <Label htmlFor="name" className="text-right">
                Your unique playbook title
              </Label>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                autoComplete={"off"}
                placeholder="My Playbook"
                className="col-span-4"
              />
            </div>
            {mutation.isError ? (
              <div>
                <p className={"font-semibold text-destructive text-sm"}>
                  {mutation.error instanceof ApiError &&
                  mutation.error.status === 409
                    ? "There is already a playbook with this title"
                    : "Something went wrong, please try again."}
                </p>
              </div>
            ) : undefined}
          </div>
          <DialogFooter>
            <DialogClose>
              <Button variant={"secondary"}>Cancel</Button>
            </DialogClose>
            <Button
              disabled={name.trim().length < 2}
              onClick={() => mutation.mutate({ title: name })}
            >
              <BookPlus className={"w-5 h-5 me-2"} />
              Create your playbook
            </Button>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default function CreatePlaybookDialog(props: PropsType) {
  const [key, setKey] = React.useState(Math.random());
  return (
    <CreatePlaybookDialogContent
      key={key}
      updateKey={() => setKey(Math.random())}
      {...props}
    />
  );
}

/*

 */

import {
  SelectContentBlueprint,
  SelectContentSubject,
  selectSubjectConfigs,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import React, { useContext, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../../ui/select";
import { PlaybookPageContext } from "../../../../../../pages/playbook/playbook-page";
import { icons } from "lucide-react";
import { cn } from "../../../../../../lib/utils";
import { SegmentContentViewProps } from "../segment-content";

const subjectIcons: Record<SelectContentSubject, keyof typeof icons> = {
  [SelectContentSubject.location]: "Globe",
  [SelectContentSubject.workingMode]: "Laptop",
  [SelectContentSubject.meetingDuration]: "Clock",
  [SelectContentSubject.meetingDurationDays]: "Clock",
  [SelectContentSubject.meetingCadence]: "Calendar",
  [SelectContentSubject.turnaroundTime]: "Calendar",
};

export default function SelectSegmentContentView({
  content,
  edit,
  isEditing,
}: SegmentContentViewProps<SelectContentBlueprint>) {
  const config = selectSubjectConfigs[content.subject];

  const { controller } = useContext(PlaybookPageContext);

  const [selected, setSelected] = useState(edit?.selected ?? content.default);

  function onSelected(selected: string) {
    if (edit) {
      setSelected(selected);
      edit.selected = selected;
      controller.editUpdated();
    }
  }

  const IconEl = icons[subjectIcons[content.subject]];

  // todo show icon in edit mode too

  const selectedOption = config.options.find((o) => o.id === selected);

  return (
    <div className={"grid grid-cols-4"}>
      <div className={"me-4 align-top py-2 font-semibold flex-shrink-0"}>
        {content.overrides?.title ?? config.title}:
      </div>
      <div className={"col-span-3 flex"}>
        {isEditing && (
          <div>
            <Select onValueChange={(v) => onSelected(v)} value={selected}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder={"Select"} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {config.options.map((op) => (
                    <SelectItem key={op.id} value={op.id}>
                      {op.displayName}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
        {!isEditing && (
          <div
            className={cn(
              !selected ? "text-muted-foreground" : null,
              "border border-secondary rounded-md p-3 flex flex-row items-center gap-2 h-9 cursor-default"
            )}
          >
            <IconEl className={"w-4 h-4"} />
            <div>
              {!selected ? "To be selected" : selectedOption!.displayName}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import Navbar from "./components/shared/navbar";
import PlaybookPage from "./pages/playbook/playbook-page";
import Footer from "./components/shared/footer";
import {
  createBrowserRouter,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import DiscoveryPage from "./pages/discovery/discovery-page";
import LoginPage from "./pages/login/login-page";
import { AuthProvider, RequireAuth } from "./auth/auth-provider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import OrgPage from "./pages/org/org-page";
import { OrgProvider, RequireOrg } from "./auth/org-provider";
import CreateOrgPage from "./pages/create-org/create-org-page";
import InvitePage from "./pages/invite/invite-page";
import LibraryPage from "./pages/library/library-page";
import BlueprintPage from "./pages/blueprint/blueprint-page";
import { ArrowRight, Laptop, Smartphone } from "lucide-react";
import { ReactComponent as TekkrScriptLogoDark } from "./static/tekkr-logo-script-dark.svg";
import { Button } from "./components/ui/button";
import { ReactComponent as TekkrLogoWhite } from "./static/tekkr-logo-white.svg";
import NoOrgPage from "./pages/no-org/no-org-page";
import { GlobalDialogsProvider } from "./modals/global-dialogs";
import { defaultQueryClient } from "./lib/query-client";
import { ContentSpacer, OnboardingPage } from "./pages/common/layout";
import { NotFoundPageContent } from "./components/shared/error-message";
import InstallSlackPage from "./pages/install-slack/install-slack-page";
import TekkrMarkdown from "./components/shared/tekkr-markdown";
import { testMarkdown } from "./lib/testing";

export function AppPage(props: React.PropsWithChildren) {
  return (
    <div className={"min-h-screen flex flex-col"}>
      <ScrollRestoration />
      <Navbar></Navbar>
      {props.children}
      <div className={"footer-spacer flex-grow"}></div>
      <Footer></Footer>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <OrgProvider>
          <RequireOrg>
            <Outlet />
          </RequireOrg>
        </OrgProvider>
      </RequireAuth>
    ),
    children: [
      {
        path: "/",
        element: (
          <AppPage>
            <DiscoveryPage></DiscoveryPage>
          </AppPage>
        ),
      },
      {
        path: "/playbook/:playbookId",
        element: (
          <AppPage>
            <PlaybookPage></PlaybookPage>
          </AppPage>
        ),
      },
      {
        path: "/blueprint/:blueprintId",
        element: (
          <AppPage>
            <BlueprintPage></BlueprintPage>
          </AppPage>
        ),
      },
      {
        path: "/org/:orgId",
        element: (
          <AppPage>
            <OrgPage></OrgPage>
          </AppPage>
        ),
      },
      {
        path: "/library",
        element: (
          <AppPage>
            <LibraryPage></LibraryPage>
          </AppPage>
        ),
      },
      {
        path: "/install-slack",
        element: (
            <OnboardingPage>
              <InstallSlackPage></InstallSlackPage>
            </OnboardingPage>
        )
      }
    ],
    errorElement: (
      <RequireAuth>
        <OrgProvider>
          <RequireOrg>
            <AppPage>
              <ContentSpacer><NotFoundPageContent /></ContentSpacer>
            </AppPage>
          </RequireOrg>
        </OrgProvider>
      </RequireAuth>
    ),
  },
  {
    path: "/invite/:inviteToken",
    element: (
      <RequireAuth>
        <InvitePage></InvitePage>
      </RequireAuth>
    ),
  },
  {
    path: "/create-organization",
    element: (
      <OrgProvider>
        <CreateOrgPage></CreateOrgPage>
      </OrgProvider>
    ),
  },
  {
    path: "/no-organization",
    element: <NoOrgPage></NoOrgPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/markdown-test",
    element: <TekkrMarkdown markdown={testMarkdown} />
  }
]);

function App() {
  const [queryClient] = React.useState(() => defaultQueryClient);
  return (
    <>
      <div
        className={
          "p-8 fixed top-0 bottom-0 left-0 right-0 bg-background z-50 flex sm:hidden gap-6 flex-col items-center justify-center"
        }
      >
        <TekkrScriptLogoDark className={"hidden dark:block fixed top-4 z-50"} />
        <div className={"flex flex-row gap-4 items-center"}>
          <Smartphone className={"w-8 h-8"} />
          <ArrowRight />
          <Laptop className={"w-8 h-8"} />
        </div>
        <h3 className={"text-center"}>
          We don't have a mobile version of Tekkr (yet) 😢
        </h3>
        <p className={"text-center"}>
          We're very sorry for the inconvenience, in the meantime you can visit
          our website to learn more about Tekkr or use Tekkr on your computer.
        </p>
        <a href={"https://tekkr.io"}>
          <Button>
            <TekkrLogoWhite className={"w-6 h-6 me-2"} />
            Learn more about Tekkr
          </Button>
        </a>
      </div>
      <div className={"hidden sm:block"}>
        <GlobalDialogsProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </QueryClientProvider>
        </GlobalDialogsProvider>
      </div>
    </>
  );
}

export default App;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basePlaybookSchema = void 0;
const zod_1 = require("zod");
const enums_1 = require("./enums/enums");
exports.basePlaybookSchema = zod_1.z.strictObject({
    id: zod_1.z.string(),
    title: zod_1.z.string(),
    summary: zod_1.z.string(),
    imageUrl: zod_1.z.string(),
    categories: zod_1.z.array(zod_1.z.nativeEnum(enums_1.PlaybookCategory)),
    teamSize: zod_1.z.object({
        min: zod_1.z.number().optional(),
        max: zod_1.z.number().optional(),
    }),
    companyStages: zod_1.z.array(zod_1.z.nativeEnum(enums_1.CompanyStage)),
    rating: zod_1.z.object({
        score: zod_1.z.number(),
        reviewCount: zod_1.z.number(),
    }),
    author: zod_1.z.object({
        name: zod_1.z.string(),
        imageUrl: zod_1.z.string(),
        role: zod_1.z.string().optional(),
    }),
});

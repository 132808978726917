import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { config } from "./lib/config";
import { buildVersion } from "./build";

if (config.env === "prod") {
  Sentry.init({
    environment: config.env,
    release: buildVersion,
    dsn: "https://b37bdacf408ada0214bb9e7513499b70@o4508133007818752.ingest.de.sentry.io/4508133013192784",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/tekkr-prod\.fly\.dev\/api/,
      /^https:\/\/tekkr-dev\.fly\.dev\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const amplitude = (window as unknown as { amplitude?: { init: (id: string, options: object) => void } }).amplitude;
if (config.env === "prod") {
  amplitude?.init("361ea5442d4bff659764815982473e06", { "fetchRemoteConfig": true, "autocapture": true });
} else if (config.env === "dev") {
  amplitude?.init("526cd656971bc4a3676549623212521d", { "fetchRemoteConfig": true, "autocapture": true });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Avatar, AvatarImage } from "../ui/avatar";
import React from "react";
import { cn } from "../../lib/utils";

export default function TeamAvatars(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn(props.className, "flex flex-row justify-center")}
    >
      <Avatar className={"w-8 h-8 border-background border-2"}>
        <AvatarImage
          src="https://media.licdn.com/dms/image/v2/D4E03AQHN9rU7J2F32g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1718321575391?e=1733356800&v=beta&t=HO9d6CxI2w-vSIoqZ3te0B1mxKuwqHKe5cn6ZvE__bs"
          alt="@filo"
        />
      </Avatar>
      <Avatar className={"w-8 h-8 -mx-2 border-background border-2"}>
        <AvatarImage
          src="https://media.licdn.com/dms/image/v2/D4D03AQESLo7sCGB_QA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1696340328667?e=1733356800&v=beta&t=ne-CqCS7ZlNfa75P2FVZ3wehF5zCIEWDfNt5-A1V3NA"
          alt="@tomi"
        />
      </Avatar>
      <Avatar className={"w-8 h-8 border-background border-2"}>
        <AvatarImage
          src="https://media.licdn.com/dms/image/v2/C4E03AQEcPWm0fTepmA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1657547204289?e=1733356800&v=beta&t=J9USizrSIG6A5a1fM4t8l0Hp6AjcRqzaNU_Rfw02rXU"
          alt="@philipp"
        />
      </Avatar>
    </div>
  );
}

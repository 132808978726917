import { Copyright } from "lucide-react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import React from "react";

function Footer() {
  return (
    <div className={"w-full px-8 py-3 flex justify-between opacity-45 mt-6"}>
      <div className={"flex gap-3 items-center"}>
        <Copyright className={"w-3 h-3"} />
        <div className={"text-xs"}>Tekkr GmbH</div>
      </div>
      <div className={"flex gap-3 items-center"}>
        <Button asChild variant={"ghost"} size={"sm2"}>
          <a
            href={"https://www.tekkr.io/contact-us"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            Contact Us
          </a>
        </Button>
        <Button variant={"ghost"} size={"sm2"} asChild>
          <Link to={"https://tekkr.io/terms-of-use"} target={"_blank"}>
            Terms of Service
          </Link>
        </Button>
        <Button variant={"ghost"} size={"sm2"}>
          <Link to={"https://tekkr.io/privacy-policy"} target={"_blank"}>
            Privacy Policy
          </Link>
        </Button>
      </div>
    </div>
  );
}

export default Footer;

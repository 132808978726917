"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectContentBlueprintSchema = exports.selectSubjectConfigs = exports.SelectContentSubject = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
var SelectContentSubject;
(function (SelectContentSubject) {
    SelectContentSubject["meetingDuration"] = "meeting_duration";
    SelectContentSubject["meetingDurationDays"] = "meeting_duration_days";
    SelectContentSubject["turnaroundTime"] = "turnaround_time";
    SelectContentSubject["meetingCadence"] = "meeting_cadence";
    SelectContentSubject["workingMode"] = "working_mode";
    SelectContentSubject["location"] = "location";
})(SelectContentSubject || (exports.SelectContentSubject = SelectContentSubject = {}));
exports.selectSubjectConfigs = {
    [SelectContentSubject.meetingDuration]: {
        title: "Meeting Duration",
        options: [
            { id: "15min", displayName: "15 Minutes" },
            { id: "30min", displayName: "30 Minutes" },
            { id: "45min", displayName: "45 Minutes" },
            { id: "60min", displayName: "60 Minutes" },
            { id: "90min", displayName: "90 Minutes" },
        ],
    },
    [SelectContentSubject.meetingDurationDays]: {
        title: "Duration",
        options: [
            { id: "0.5d", displayName: "0.5 Days" },
            { id: "1d", displayName: "1 Day" },
            { id: "1.5d", displayName: "1.5 Days" },
            { id: "2d", displayName: "2 Days" },
            { id: "3d", displayName: "3 Days" },
        ],
    },
    [SelectContentSubject.workingMode]: {
        title: "Working Mode",
        options: [
            { id: "asynchronous", displayName: "Asynchronous" },
            { id: "meeting_in_person", displayName: "Meeting (in person)" },
            { id: "meeting_virtual", displayName: "Meeting (virtual)" },
        ],
    },
    [SelectContentSubject.meetingCadence]: {
        title: "Meeting Cadence",
        options: [
            { id: "weekly", displayName: "Every week" },
            { id: "two_weeks", displayName: "Every two weeks" },
            { id: "three_weeks", displayName: "Every three weeks" },
            { id: "monthly", displayName: "Every month" },
            { id: "quarterly", displayName: "Quarterly" },
            { id: "once", displayName: "Once" },
        ],
    },
    [SelectContentSubject.location]: {
        title: "Location",
        options: [
            { id: "in_person", displayName: "Physical / In Person" },
            { id: "remote", displayName: "Remote" },
        ],
    },
    [SelectContentSubject.turnaroundTime]: {
        title: "Timeline",
        options: [
            { id: "1d", displayName: "1 day" },
            { id: "2d", displayName: "2 days" },
            { id: "3d", displayName: "3 days" },
            { id: "4d", displayName: "4 days" },
            { id: "5d", displayName: "5 days" },
            { id: "6d", displayName: "6 days" },
            { id: "1w", displayName: "1 week" },
            { id: "2w", displayName: "2 weeks" },
        ],
    }
};
exports.selectContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.ContentType.select),
    subject: zod_1.z.nativeEnum(SelectContentSubject),
    overrides: zod_1.z.object({
        title: zod_1.z.string().optional(),
    }).optional(),
    default: zod_1.z.string().optional(),
    descriptionMarkdown: zod_1.z.string().optional(),
});

import { SectionContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/section/blueprint";
import React from "react";
import { SegmentContentViewProps } from "../segment-content";

export default function SectionSegmentContentView(
  props: SegmentContentViewProps<SectionContentBlueprint>
) {
  return (
    <div>
      <hr className={"-mx-10 border-0 border-b border-b-accent my-2"} />
      {props.content.title ? (
        <h3 className={"mb-4"}>{props.content.title}</h3>
      ) : null}
    </div>
  );
}

import { CompanyStage } from "tekkr-common/dist/model/playbook/enums/enums";

const seriesStageNames: Partial<Record<CompanyStage, string>> = {
  [CompanyStage.seriesA]: "A",
  [CompanyStage.seriesB]: "B",
  [CompanyStage.seriesC]: "C",
};

export const formats = {
  friendly: {
    teamSizeApplicability({
      min,
      max,
    }: {
      min?: number;
      max?: number;
    }): string {
      if (min && max) {
        return `${min} - ${max}`;
      } else if (min) {
        return `${min}+`;
      } else if (max) {
        return `< ${max}`;
      } else {
        return "any size";
      }
    },
    companyStageApplicability(stages: CompanyStage[]): string {
      if (stages.length === Object.keys(CompanyStage).length) {
        return "any stage";
      }
      const result: string[] = [];
      if (stages.includes(CompanyStage.seed)) {
        result.push("Seed");
      }
      const series: string[] = [];
      for (const stage of stages) {
        const name = seriesStageNames[stage];
        if (name) {
          series.push(name);
        }
      }
      if (series.length > 0) {
        result.push(`Series ${series.sort().join("/")}`);
      }
      return result.join(", ");
    },
    rating(rating: { score: number, reviewCount: number }) {
      return rating.score.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }
  },
};

import {
  ExternalLinkContentBlueprint,
  ExternalLinkStyle,
} from "tekkr-common/dist/model/playbook/segment/content/types/external-link/blueprint";
import { Link } from "react-router-dom";
import { BookOpen, CircleDollarSign, Link as LinkIcon } from "lucide-react";
import { SegmentContentViewProps } from "../segment-content";
import React from "react";
import { useGlobalDialogs } from "../../../../../../modals/global-dialogs";

function ExternalLinkToTool({
  content,
}: {
  content: ExternalLinkContentBlueprint;
}) {
  return (
    <div
      className={
        "border transition-all hover:bg-accent rounded-md px-4 py-3 grid grid-cols-3 items-center justify-between space-x-4 relative"
      }
    >
      <img
        src={content.imgUrl}
        alt={content.title}
        className={
          "aspect-3/2 rounded-md overflow-hidden bg-accent object-cover"
        }
        style={{ objectPosition: "top left" }}
      />
      <div className={"col-span-2 pe-8"}>
        <h4>{content.title}</h4>
        {content.description ? (
          <p className={"text-muted-foreground"}>{content.description}</p>
        ) : null}
      </div>
      <LinkIcon className={"absolute top-4 right-4 w-4 h-4 opacity-65"} />
    </div>
  );
}

function ExternalLinkToBook({
  content,
}: {
  content: ExternalLinkContentBlueprint;
}) {
  return (
    <div
      className={
        "group transition-all flex flex-row items-stretch gap-2 relative"
      }
    >
      <img
        src={content.imgUrl}
        alt={content.title}
        className={
          "shrink-0 h-24 max-w-24 rounded-sm overflow-hidden bg-accent object-cover"
        }
        style={{ objectPosition: "top left" }}
      />
      <div
        className={
          "transition-all pe-8 group-hover:bg-accent rounded-md px-3 py-1 w-full relative flex flex-col justify-center"
        }
      >
        <BookOpen className={"w-5 h-5 text-muted-foreground mb-2"} />
        <h4>{content.title}</h4>
        {content.description ? (
          <p className={"text-muted-foreground"}>{content.description}</p>
        ) : null}
        <div
          className={
            "hidden absolute right-4 bottom-2 text-muted-foreground text-xs opacity-0 group-hover:opacity-65 transition-all duration-500 flex flex-row items-center gap-2"
          }
        >
          <CircleDollarSign className={"inline w-4 h-4"} /> this is a referral
          link
        </div>
      </div>
    </div>
  );
}

export default function ExternalLinkSegmentContentView(
  props: SegmentContentViewProps<ExternalLinkContentBlueprint>
) {
  const globalDialogs = useGlobalDialogs();
  const onClick =
    props.content.link === "https://app.tekkr.io/unlock"
      ? () => {
          globalDialogs?.showUnlockAllPlaybooksCta();
        }
      : undefined;

  const content = (
    <>
      {props.content.style === ExternalLinkStyle.tool && (
        <ExternalLinkToTool content={props.content} />
      )}
      {props.content.style === ExternalLinkStyle.book && (
        <ExternalLinkToBook content={props.content} />
      )}
    </>
  );

  if (onClick) {
    return (
      <div onClick={onClick} className={"cursor-pointer"}>
        {content}
      </div>
    );
  } else {
    return (
      <Link target={"_blank"} to={props.content.link} className={"my-2"}>
        {content}
      </Link>
    );
  }
}

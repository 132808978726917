import {
  Check,
  Delete,
  Edit,
  PartyPopper,
  RotateCw,
  Save,
  Trash,
  Trash2,
  TriangleAlert,
  User,
} from "lucide-react";
import React, { createContext, useEffect } from "react";
import { ContentSpacer } from "../common/layout";
import { apiClient, ApiError } from "../../service/tekkr-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../components/ui/spinner";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { computePlaybookProgress, delay } from "../../lib/utils";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import PlaybookView from "../../components/shared/playbook-view/playbook-view";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import debounce from "debounce";
import { Button } from "../../components/ui/button";
import { PlaybookHeader } from "../../components/shared/playbook-view/playbook-header";
import { useOrg } from "../../auth/org-provider";
import { DateTime } from "luxon";
import { config } from "../../lib/config";
import { confetti } from "@tsparticles/confetti";
import ConfirmationDialog from "../../modals/confirmation-dialog";
import { DialogTrigger } from "../../components/ui/dialog";
import { InternalErrorPageContent, NotFoundPageContent } from "../../components/shared/error-message";

export interface PlaybookPageNavigationState {
  openEditMode?: boolean;
}

const getPlaybookQuery = (playbookId: string) => ({
  queryKey: [`playbook-${playbookId}-get`],
  queryFn: async () => {
    const playbookResponse = await (
      await apiClient
    ).getPlaybookById({ params: { playbookId } });
    const playbook = (
      playbookResponse.body as ClientInferResponseBody<
        typeof apiContract.getPlaybookById,
        200
      >
    ).data;
    const blueprintResponse = await (
      await apiClient
    ).getBlueprintById({
      params: { id: playbook.blueprintId },
    });
    const blueprint = blueprintResponse.body as ClientInferResponseBody<
      typeof apiContract.getBlueprintById,
      200
    > as PlaybookBlueprint;
    return { playbook, blueprint };
  },
});

const updatePlaybookEditQuery = (playbookId: string, edit?: PlaybookEdit) => ({
  // todo use mutation here
  queryKey: [`playbook-${playbookId}-update-edit`],
  queryFn: async () => {
    if (!edit) throw new Error("missing edit");
    const start = Date.now();
    await (
      await apiClient
    ).updatePlaybookEdit({
      params: { playbookId },
      body: edit,
    });
    // wait if too quick
    if (Date.now() - start < 100) {
      await delay(200 - (Date.now() - start));
    }
    return true;
  },
  enabled: !!edit,
});

export interface PlaybookPageController {
  editUpdated: () => void;
}

interface PlaybookPageContextType {
  controller: PlaybookPageController;
}

function useCloseConfirmation(requireConfirmation: boolean) {
  useEffect(() => {
    const onBeforeUnload = (ev: Event) => {
      if (requireConfirmation) {
        ev.preventDefault();
        ev.returnValue = requireConfirmation;
        return requireConfirmation;
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [requireConfirmation]);
}

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const triggerConfetti = () => {
  for (const i of [1, 2, 3, 5, 6, 8]) {
    setTimeout(() => {
      void confetti({
        angle: randomInRange(220, 250),
        spread: randomInRange(50, 70),
        particleCount: 40,
        origin: { x: randomInRange(0.85, 1), y: 0.05 },
        shapes: ["circle", "square"],
      });
    }, i * 100);
  }
  setTimeout(() => {
    void confetti({
      angle: randomInRange(270, 270),
      spread: 180,
      particleCount: 30,
      origin: { x: 0.5, y: 0.0 },
      shapes: ["emoji"],
      scalar: 4,
      shapeOptions: {
        emoji: {
          value: ["🦄"],
        },
      },
    });
  }, 900);
  setTimeout(() => {
    void confetti({
      angle: randomInRange(-30, -60),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { x: 0.05, y: 0.05 },
    });
  }, 150);
  setTimeout(() => {
    void confetti({
      angle: randomInRange(-10, -30),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { x: 0, y: 0.15 },
    });
  }, 250);
  setTimeout(() => {
    void confetti({
      angle: randomInRange(-10, -30),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { x: 0, y: 0.2 },
    });
  }, 350);
};

const PlaybookPageContext = createContext<PlaybookPageContextType>(
  {} as PlaybookPageContextType
);

type EditSession = {
  startingProgress: number;
};

function PlaybookPage() {
  const { playbookId } = useParams();
  if (!playbookId) {
    throw new Error("missing playbook id");
  }

  const location = useLocation();
  const navState = location.state as PlaybookPageNavigationState | undefined;
  const navigate = useNavigate();
  const org = useOrg();

  const [editSession, setEditSession] = React.useState<EditSession | undefined>(
    undefined
  );
  const { isPending, isError, error, data, refetch } = useQuery(
    getPlaybookQuery(playbookId)
  );

  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: async () => {
      await (
        await apiClient
      ).deletePlaybook({
        params: { playbookId },
        body: undefined,
      });
      await queryClient.invalidateQueries({
        queryKey: ["library-playbooks"],
      });
      navigate("/");
    },
  });

  function startEditSession() {
    if (!data) {
      throw new Error(
        "cannot start editing session before playbook data is loaded"
      );
    }
    setEditSession({
      startingProgress:
        computePlaybookProgress(data.blueprint, data.playbook.edit) ?? 0,
    });
  }
  function endEditSession() {
    if (editSession && editSession.startingProgress < 1) {
      const newProgress = data
        ? computePlaybookProgress(data.blueprint, data.playbook.edit)
        : 0;
      if (newProgress === 1) {
        triggerConfetti();
      }
    }
    setEditSession(undefined);
  }

  // wait until data is loaded and then start editing session if requested by navigation
  useEffect(() => {
    if (navState?.openEditMode && !!data) {
      startEditSession();
    }
  }, [!!data]);

  useCloseConfirmation(!!editSession);

  useEffect(() => {
    document.title = `${editSession ? "Editing: " : ""} ${data?.playbook?.edit?.title ?? data?.blueprint.title ?? "Playbook"} - Tekkr`;
  }, [data, editSession]);

  const updateQuery = useQuery(
    updatePlaybookEditQuery(playbookId, data?.playbook.edit)
  );

  let content = (
    <div className={"w-full flex flex-col items-center"}>
      <Spinner className={"w-8 h-8"}></Spinner>
      <div className={"mt-4 text-sm text-secondary-foreground"}>Loading...</div>
    </div>
  );

  if (isError) {
    if (error instanceof ApiError && error.status === 404) {
      content = <NotFoundPageContent />;
    } else {
      content = <InternalErrorPageContent />;
    }
  } else if (deleteMutation.isPending) {
    content = <div className={"flex flex-row items-center justify-center gap-2 font-semibold"}><Spinner /> Deleting Playbook...</div>
  } else if (!isPending) {
    const playbookEdit = data.playbook.edit as PlaybookEdit;

    if (!playbookEdit.segments) {
      playbookEdit.segments = {};
    }

    const pageContext = {
      controller: {
        editUpdated: debounce(() => {
          void updateQuery.refetch();
        }, 500),
      },
    };

    content = (
      <>
        <PlaybookPageContext.Provider value={pageContext}>
          <PlaybookHeader
            blueprint={data?.blueprint}
            playbookEdit={playbookEdit}
          >
            <div
              className={
                "flex flex-row items-center gap-3 mb-6 text-muted-foreground"
              }
            >
              <p>Created by</p>
              <div
                className={
                  " text-muted-foreground flex flex-row gap-2 px-2 py-1 border rounded-md items-center"
                }
              >
                <User className={"w-5 h-5"} />
                <span>
                  {
                    org.users.find((u) => u.id === data?.playbook.createdBy)!
                      .name
                  }
                </span>
              </div>
              <p>
                on{" "}
                {DateTime.fromISO(data?.playbook.createdAt).toLocaleString({
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </p>
            </div>
          </PlaybookHeader>
          <PlaybookView
            blueprint={data?.blueprint}
            playbookEdit={playbookEdit}
            isEditing={!!editSession}
          />

          <div className={"mt-10"}>
            <ConfirmationDialog
              title={"Delete this Playbook?"}
              confirmButtonVariant={"destructive"}
              body={
                <p>
                  Do you really want to delete the playbook "
                  <b>{playbookEdit.title}</b>"?
                </p>
              }
              onConfirm={() => {
                deleteMutation.mutate();
              }}
            >
              <DialogTrigger>
                <Button variant={"outline-destructive"} size={"sm"}>
                  <Trash2 className={"w-5 h-5 me-2"} /> Delete this playbook
                </Button>
              </DialogTrigger>
            </ConfirmationDialog>
          </div>

          <div
            className={
              "fixed top-0 right-24 flex flex-row items-center h-16 w-50 z-50"
            }
          >
            {editSession ? (
              <div
                className={
                  "rounded-md px-4 py-1.5 border-2 text-accent-foreground font-semibold text-sm mx-3"
                }
              >
                <div className={"opacity-65"}>
                  {updateQuery.isFetching ? (
                    <div className={"flex flex-row gap-2 items-center"}>
                      <Spinner className={"w-5 h-5"}></Spinner> saving changes
                    </div>
                  ) : null}
                  {!updateQuery.isError && !updateQuery.isFetching ? (
                    <div className={"flex flex-row gap-2 items-center"}>
                      <Save className={"w-5 h-5"} /> changes saved
                    </div>
                  ) : null}
                  {updateQuery.isError && (
                    <div className={"flex flex-row gap-2 items-center"}>
                      <TriangleAlert />
                      &nbsp; Changes could not be saved! &nbsp;
                      <u>
                        <a href={"https://www.tekkr.io/contact-us"}>
                          Contact us.
                        </a>
                      </u>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {editSession ? (
              <Button size={"sm"} onClick={() => endEditSession()}>
                <Check className={"w-5 h-5"} />
                <span className={"ms-2"}>Done Editing</span>
              </Button>
            ) : (
              <Button
                size={"sm"}
                className={"px-4"}
                onClick={() => startEditSession()}
              >
                <Edit className={"w-5 h-5"} />
                <span className={"ms-2"}>Edit</span>
              </Button>
            )}
          </div>
        </PlaybookPageContext.Provider>
      </>
    );
  }

  const refetchAll = () => {
    refetch();
  };

  return (
    <div className={"px-8 py-6"}>
      <ContentSpacer contentWidth={"narrower"}>
        {config.env === "local" && (
          <div
            className={
              "construction-tape-bg border-2 border-dotted rounded-md px-3 py-1 border-yellow-300 flex flex-row gap-4"
            }
          >
            <Button variant={"outline-secondary"} onClick={refetchAll}>
              <RotateCw className={"w-5 h-5 me-2"} />
              Refetch
            </Button>
            <Button
              variant={"outline-secondary"}
              onClick={() => triggerConfetti()}
            >
              <PartyPopper className={"w-5 h-5 me-2"} />
              Confetti
            </Button>
          </div>
        )}
        {content}
      </ContentSpacer>
    </div>
  );
}

export { PlaybookPageContext };

export default PlaybookPage;

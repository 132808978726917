import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient, ApiError } from "../service/tekkr-service";
import { useOrgController } from "../auth/org-provider";
import Spinner from "../components/ui/spinner";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../components/ui/button";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox } from "../components/ui/checkbox";

type PropsType = React.PropsWithChildren & {
  onPersonCreated?: (id: string) => void;
};

const formSchema = z.object({
  name: z.string().min(2).max(50),
  title: z.string().max(50).optional(),
  email: z.string().min(2).max(50).email(),
  sendInvite: z.boolean(),
});

type FormType = z.infer<typeof formSchema>;

function getErrorMessage(e: Error) {
  if (e instanceof ApiError) {
    if (e.status === 409) {
      return "A person with this email address already exists.";
    }
  }
  return "Creating this person failed, please try again later.";
}

function CreatePersonDialogContent(
  props: PropsType & { updateKey: () => void }
) {
  const orgController = useOrgController();

  const mutation = useMutation({
    mutationFn: async (formData: FormType) => {
      const response = await (
        await apiClient
      ).createOrgUser({
        body: {
          name: formData.name,
          title: formData.title,
          email: formData.email,
        },
        query: {
          emailInvite: formData.sendInvite ? "true" : "false",
        },
      });
      const user = response.body as ClientInferResponseBody<
        typeof apiContract.createOrgUser,
        201
      >;
      await orgController.refetch();
      resetDialog();
      if (props.onPersonCreated) {
        props.onPersonCreated(user.id);
      }
    },
  });

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      sendInvite: false,
    },
  });
  function onSubmit(values: FormType) {
    mutation.mutate(values);
  }

  function resetDialog() {
    props.updateKey();
  }

  const [open, setOpenState] = React.useState(false);
  const setOpen = (open: boolean) => {
    if (mutation.isPending) return;
    if (!open) {
      resetDialog();
    }
    setOpenState(open);
  };

  const values = form.getValues();

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      {props.children}
      {mutation.isPending ? (
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Add Person</DialogTitle>
          </DialogHeader>
          <div className={"flex flex-col items-center h-32 justify-center"}>
            <Spinner></Spinner>
            <div className={"text-sm font-semibold mt-2"}>
              Creating Person...
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className={"text-start"}>Add Person</DialogTitle>
            <DialogDescription className={"text-start"}>
              Add someone to your organization to reference them here and in
              other places.
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className={"grid grid-cols-5 items-center"}>
                    <FormLabel className={"pe-2"}>Name</FormLabel>
                    <FormControl className={"col-span-4"}>
                      <Input
                        autoComplete={"off"}
                        placeholder="John Doe"
                        {...field}
                      />
                    </FormControl>
                    <div></div>
                    <FormMessage className={"col-span-4"} />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className={"grid grid-cols-5 items-center"}>
                    <FormLabel className={"pe-2 block"}>
                      <div>
                        Title<br/><p className={"text-xs mt-1 text-muted-foreground"}>(optional)</p>
                      </div>
                    </FormLabel>
                    <FormControl className={"col-span-4"}>
                      <Input
                        autoComplete={"off"}
                        placeholder="Software Engineer"
                        {...field}
                      />
                    </FormControl>
                    <div></div>
                    <FormMessage className={"col-span-4"} />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className={"grid grid-cols-5 items-center"}>
                    <FormLabel className={"pe-2"}>Email</FormLabel>
                    <FormControl className={"col-span-4"}>
                      <Input
                        autoComplete={"off"}
                        placeholder="john@company.com"
                        {...field}
                      />
                    </FormControl>
                    <div></div>
                    <FormMessage className={"col-span-4"} />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"sendInvite"}
                render={({ field }) => (
                  <div className={"grid grid-cols-5 items-center"}>
                    <div></div>
                    <FormItem
                      className={
                        "col-span-4 flex flex-row gap-2 items-top space-y-0"
                      }
                    >
                      <FormControl className={"col-span-4 mt-1"}>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div>
                        <FormLabel className={"mt-0"}>
                          Invite them to join Tekkr
                        </FormLabel>
                        <p className={"text-sm text-muted-foreground"}>
                          Check this if you want us to send an email to this
                          person inviting them to join Tekkr.
                        </p>
                      </div>
                    </FormItem>
                  </div>
                )}
              />
              <div className={"flex flex-row justify-end pt-4"}>
                <Button type={"submit"}>
                  {values.sendInvite ? "Save and Invite" : "Save"}
                </Button>
              </div>
            </form>
          </Form>

          {mutation.isError ? (
            <div
              className={
                "border border-destructive py-2 px-4 rounded-md text-destructive text-sm"
              }
            >
              {getErrorMessage(mutation.error)}
            </div>
          ) : null}
        </DialogContent>
      )}
    </Dialog>
  );
}

export default function CreatePersonDialog(props: PropsType) {
  const [key, setKey] = React.useState(Math.random());
  return (
    <CreatePersonDialogContent
      key={key}
      updateKey={() => setKey(Math.random())}
      {...props}
    />
  );
}
